<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Nr crt</v-table-th>
        <v-table-th>Suma</v-table-th>
        <v-table-th>Status</v-table-th>
        <v-table-th>Actiuni</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="(row, index) in rows" :key="row.id">
        <v-table-td>
          {{ index + 1 }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.amount) }}
        </v-table-td>
        <v-table-td>
          <span class="pill-success">
            {{ row.status.label }}
          </span>
        </v-table-td>
        <v-table-td>
          <modal-update-vendor-payment-status
            :vendor-payment-id="row.id"
            @update:vendorPayment="$emit('update:status', $event)"
          />
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VTable from "@/components/ui/VTable.vue";
import VTableTd from "@/components/ui/VTableTd.vue";
import VTableTh from "@/components/ui/VTableTh.vue";
import VTbody from "@/components/ui/VTbody.vue";
import VThead from "@/components/ui/VThead.vue";
import ModalUpdateVendorPaymentStatus from "@/components/ModalUpdateVendorPaymentStatus.vue";

export default {
  components: {
    VTableTd,
    VTableTh,
    VTable,
    VThead,
    VTbody,
    ModalUpdateVendorPaymentStatus,
  },

  props: {
    rows: {
      type: Array,
    },
  },
  emits: ["update:status"],

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
