<template>
  <v-card>
    <loader v-if="loading" class="my-5" />
    <template v-else>
      <template v-if="totalRows">
        <vendor-orders-show-card-payments-table
          :rows="rows"
          @update:status="updateVendorPayment"
        />

        <pagination
          @change:page="index($event)"
          :total="meta.total"
          :current_page="meta.current_page"
          :from="meta.from"
          :to="meta.to"
          :next_page_url="links.next"
          :prev_page_url="links.prev"
        />
      </template>

      <no-results v-else class="m-5" />
    </template>
  </v-card>
</template>

<script>
import vendorOrdersApi from "../api/resources/vendorOrdersApi";
import Loader from "./Loader.vue";
import NoResults from "./NoResults.vue";
import VendorOrdersShowCardPaymentsTable from "./VendorOrdersShowCardPaymentsTable.vue";
import Pagination from "./Pagination.vue";
import VCard from "@/components/ui/VCard.vue";

export default {
  props: {
    orderId: String,
  },

  components: {
    Pagination,
    VendorOrdersShowCardPaymentsTable,
    VCard,
    Loader,
    NoResults,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    updateVendorPayment(vendorPayment) {
      this.rows.forEach((row) => {
        if (
          row.id === vendorPayment.id &&
          row.status.id !== vendorPayment.status.id
        ) {
          this.index();
        }
      });
    },

    index(page = null) {
      this.loading = true;
      vendorOrdersApi
        .paymentsIndex(this.orderId, page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.index();
  },
};
</script>

<style></style>
