<template>
  <app-layout>
    <template #header> Detalii comanda vanzator</template>

    <page-section>
      <page-section-header>
        # {{ id }}

        <template #meta>
          <span class="text-sm color-gray-300 font-normal custom">
            {{ order.status?.name }}
          </span>
        </template>

        <template v-slot:actions>
          <modal-update-vendor-order-status
            @update:order="
              (order) => {
                this.order = order;
              }
            "
            :order-id="id"
          />
          <!-- <vendor-orders-show-button-payment class="ml-2" /> -->
        </template>
      </page-section-header>
      <page-section-body>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-3">
          <vendor-orders-show-card-shipping :order="order" />
          <vendor-orders-show-card-billing :order="order" />
          <vendor-orders-show-card-payment :order="order" />
        </div>

        <vendor-orders-show-card-items
          class="mt-3"
          :order-id="$route.params.id"
        />
        <vendor-orders-show-card-payments
          class="mt-3"
          :order-id="$route.params.id"
        />
      </page-section-body>
    </page-section>
  </app-layout>
</template>

<script>
import AppLayout from "../layout/AppLayout.vue";
import vendorOrdersApi from "../api/resources/vendorOrdersApi";
import VendorOrdersShowCardItems from "../components/VendorOrdersShowCardItems.vue";
import VendorOrdersShowCardShipping from "../components/VendorOrdersShowCardShipping.vue";
import VendorOrdersShowCardBilling from "../components/VendorOrdersShowCardBilling.vue";
import VendorOrdersShowCardPayment from "../components/VendorOrdersShowCardPayment.vue";
import VendorOrdersShowCardPayments from "../components/VendorOrdersShowCardPayments.vue";
import PageSection from "../components/ui/PageSection.vue";
import PageSectionHeader from "../components/ui/PageSectionHeader.vue";
import PageSectionBody from "../components/ui/PageSectionBody.vue";
import ModalUpdateVendorOrderStatus from "../components/ModalUpdateVendorOrderStatus.vue";
// import VendorOrdersShowButtonPayment from "../components/VendorOrdersShowButtonPayment.vue";

export default {
  components: {
    AppLayout,
    VendorOrdersShowCardItems,
    VendorOrdersShowCardShipping,
    VendorOrdersShowCardBilling,
    VendorOrdersShowCardPayment,
    VendorOrdersShowCardPayments,
    PageSection,
    PageSectionHeader,
    PageSectionBody,
    ModalUpdateVendorOrderStatus,
    // VendorOrdersShowButtonPayment,
  },

  props: ["id"],

  data() {
    return {
      order: {},
    };
  },

  created() {
    vendorOrdersApi.show(this.$route.params.id).then((response) => {
      this.order = response.data;
    });
  },
};
</script>

<style></style>
