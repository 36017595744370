<template>
  <v-card>
    <v-card-body>
      <v-card-title>Detalii facturare</v-card-title>
      <ul>
        <li>Nume: {{ order.billing_address?.name }}</li>
        <li>Adresa: {{ order.billing_address?.street }}</li>
        <li>Tel: {{ order.billing_address?.phone }}</li>
      </ul>
    </v-card-body>
  </v-card>
</template>

<script>
import VCard from "@/components/ui/VCard.vue";
import VCardBody from "@/components/ui/VCardBody.vue";
import VCardTitle from "@/components/ui/VCardTitle.vue";
export default {
  components: { VCard, VCardBody, VCardTitle },
  props: {
    order: {
      type: Object,
    },
  },
};
</script>

<style>
</style>