<template>
  <v-card>
    <v-card-body>
      <v-card-title>Detalii plata</v-card-title>
      <ul>
        <li>Metoda: {{ order.payment?.method }}</li>
        <!-- <li>Total:</li>
        <li>Platit:</li> -->
      </ul>
    </v-card-body>
  </v-card>
</template>

<script>
import VCard from "@/components/ui/VCard.vue";
import VCardBody from "@/components/ui/VCardBody.vue";
import VCardTitle from "@/components/ui/VCardTitle.vue";
export default {
  components: { VCard, VCardBody, VCardTitle },
  props: {
    order: {
      type: Object,
    },
  },
};
</script>

<style></style>
